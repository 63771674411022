/*!
 * Bootstrap v3.2.0 (https://getbootstrap.com)
 * Copyright 2011-2014 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

@media (min-width: 1200px) {
  .container {
    width: 1040px;
  }
}
.btn-default:active,
.btn-primary:active,
.btn-success:active,
.btn-info:active,
.btn-warning:active,
.btn-danger:active,
.btn-default.active,
.btn-primary.active,
.btn-success.active,
.btn-info.active,
.btn-warning.active,
.btn-danger.active {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
          box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
}
.btn:active,
.btn.active {
  background-image: none;
}
.btn-default {
  text-shadow: 0 1px 0 #fff;
  background-image: none;
  background-image: none;
  background-image: none;
  background-image: none;
  filter: none;
  filter: none;
  background-repeat: repeat-x;
  background-color:#fff;
  border-color: #e8e8e8;
  border-color: #e8e8e8;
}
.btn-default:hover,
.btn-default:focus {
  background-color: #fafafa;
  border-color: #e8e8e8;
  background-position: 0 -15px;
}
.btn-default:active,
.btn-default.active {
  background-color: #fafafa;
  border-color: #e8e8e8;
}
.btn-default:disabled,
.btn-default[disabled] {
  background-color: #fafafa;
  background-image: none;
}
.btn-primary {
  border-color: #2b669a;
}
.btn-primary:hover,
.btn-primary:focus {
  background-color: #2d6ca2;
}
.btn-primary:active,
.btn-primary.active {
  background-color: #2d6ca2;
  border-color: #2b669a;
}
.btn-primary:disabled,
.btn-primary[disabled] {
  background-color: #2d6ca2;
}
.btn-success {
  border-color: #3e8f3e;
}
.btn-success:hover,
.btn-success:focus {
  background-color: #419641;
}
.btn-success:active,
.btn-success.active {
  background-color: #419641;
  border-color: #3e8f3e;
}
.btn-success:disabled,
.btn-success[disabled] {
  background-color: #419641;
}
.btn-info {
  border-color: #28a4c9;
}
.btn-info:hover,
.btn-info:focus {
  background-color: #2aabd2;
}
.btn-info:active,
.btn-info.active {
  background-color: #2aabd2;
  border-color: #28a4c9;
}
.btn-info:disabled,
.btn-info[disabled] {
  background-color: #2aabd2;
}
.btn-warning {
  border-color: #e38d13;
}
.btn-warning:hover,
.btn-warning:focus {
  background-color: #eb9316;
}
.btn-warning:active,
.btn-warning.active {
  background-color: #eb9316;
  border-color: #e38d13;
}
.btn-warning:disabled,
.btn-warning[disabled] {
  background-color: #eb9316;
}
.btn-danger {
  border-color: #b92c28;
}
.btn-danger:hover,
.btn-danger:focus {
  background-color: #c12e2a;
}
.btn-danger:active,
.btn-danger.active {
  background-color: #c12e2a;
  border-color: #b92c28;
}
.btn-danger:disabled,
.btn-danger[disabled] {
  background-color: #c12e2a;
}
.btn-default,
.btn-primary,
.btn-success,
.btn-info,
.btn-warning,
.btn-danger {
  -webkit-border-radius:20px;
  border-radius:20px;
  padding-left:20px;
  padding-right:20px;
  -webkit-transition:all 0.3s ease 0.2s;
  -moz-transition:all 0.3s ease 0.2s;
  -ms-transition:all 0.3s ease 0.2s;
  -o-transition:all 0.3s ease 0.2s;
  transition:all 0.3s ease 0.1s;
  text-shadow: none;
  border-width:0;
  text-decoration:none;
  -webkit-box-shadow: none;
          box-shadow: none;
  font-family: 'Roboto', sans-serif;
  text-transform:uppercase;
  letter-spacing:.07em;
  line-height: 1.66857143;
  font-weight:700;
  color:rgba(255,255,255,.9);
  position:relative;
  font-size:12px;
}
.btn-default{
	border-width:0;
	color:#333;
	border:1px solid #DDD;
}
.btn-sm,
.btn-group-sm > .btn{
  -webkit-border-radius:3px;
  border-radius:3px;
  font-size:11px;
  padding-left:8px;
  padding-right:8px;
}
.input-group-btn .btn-default,
.input-group-btn .btn-primary,
.input-group-btn .btn-success,
.input-group-btn .btn-info,
.input-group-btn .btn-warning,
.input-group-btn .btn-danger {
  -webkit-border-radius:4px;
  border-radius:4px;
}
.thumbnail,
.img-thumbnail {
  	background-color:#ffffff;
	border:1px solid #e8e8e8;
}
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  background-color: #e8e8e8;
}
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
  background-color: #357ebd;
}
.navbar-default {
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 5px rgba(0, 0, 0, .075);
          box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 5px rgba(0, 0, 0, .075);
}
.navbar-default .navbar-nav > .active > a {
  -webkit-box-shadow: inset 0 3px 9px rgba(0, 0, 0, .075);
          box-shadow: inset 0 3px 9px rgba(0, 0, 0, .075);
}
.navbar-brand,
.navbar-nav > li > a {
  text-shadow: 0 1px 0 rgba(255, 255, 255, .25);
}
.navbar-inverse {
}
.navbar-inverse .navbar-nav > .active > a {
  -webkit-box-shadow: inset 0 3px 9px rgba(0, 0, 0, .25);
          box-shadow: inset 0 3px 9px rgba(0, 0, 0, .25);
}
.navbar-inverse .navbar-brand,
.navbar-inverse .navbar-nav > li > a {
  text-shadow: 0 -1px 0 rgba(0, 0, 0, .25);
}
.navbar-static-top,
.navbar-fixed-top,
.navbar-fixed-bottom {
  border-radius: 0;
}
.alert {
  text-shadow: 0 1px 0 rgba(255, 255, 255, .2);
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, .25), 0 1px 2px rgba(0, 0, 0, .05);
          box-shadow: inset 0 1px 0 rgba(255, 255, 255, .25), 0 1px 2px rgba(0, 0, 0, .05);
}
.alert-success {
  border-color: #b2dba1;
}
.alert-info {
  border-color: #9acfea;
}
.alert-warning {
  border-color: #f5e79e;
}
.alert-danger {
  border-color: #dca7a7;
}
.progress {
}
.progress-bar {
}
.progress-bar-success {
}
.progress-bar-info {
}
.progress-bar-warning {
}
.progress-bar-danger {
}
.progress-bar-striped {
}
.list-group {
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, .075);
          box-shadow: 0 1px 2px rgba(0, 0, 0, .075);
}
.list-group-item.active,
.list-group-item.active:hover,
.list-group-item.active:focus {
  border-color: #3278b3;
}
.panel {
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, .05);
          box-shadow: 0 1px 2px rgba(0, 0, 0, .05);
}
.panel-default > .panel-heading {
}
.panel-primary > .panel-heading {
}
.panel-success > .panel-heading {
}
.panel-info > .panel-heading {
}
.panel-warning > .panel-heading {
}
.panel-danger > .panel-heading {
}
.well {
  border-color: #dcdcdc;
  -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, .05), 0 1px 0 rgba(255, 255, 255, .1);
          box-shadow: inset 0 1px 3px rgba(0, 0, 0, .05), 0 1px 0 rgba(255, 255, 255, .1);
}
/* ==================================================
   Tabs
================================================== */
.nav-tabs-bar{
	width:100%;
	padding-right:20px;
	height:34px;
	line-height:32px;
	background:#fafafa;
	border:1px solid #e8e8e8;
}
.tabs{
}
.nav-tabs{
	border:0;
	float:left;
}
.nav-tabs > li{
	position:relative;
	display:block;
	float:left;
	font-family: 'Roboto', sans-serif;
	text-transform:uppercase;
	font-weight:400;
	font-size:12px;
}
.nav-tabs > li > a{
	-webkit-border-radius:0;
	border-radius:0;
	line-height:32px;
	display:block;
	padding:0 20px;
	text-decoration:none;
	background:#fafafa;
	border:0;
	margin-right:0;
	padding-right:21px;
	border-right:1px solid #e8e8e8;
  	border-bottom: 1px solid transparent;
}
.nav-tabs > li > a:hover{
	background:none;
	border:0;
	border-right:1px solid #e8e8e8;
	text-decoration:none;
  	border-bottom: 1px solid transparent;
}
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus{
	border:0;
	border-right:1px solid #e8e8e8;
  	border-bottom-width: 1px;
	border-bottom-style:solid;
}
.tab-content{
	padding:50px 0 0;
}
.tab-content > .tab-pane{
	display:none;
}
.tab-content > .active{
	display:block;
}
/* ==================================================
   Accordion & Toggle
================================================== */
.accordion {
  	margin-bottom: 35px;
}

.accordion-group {
  	margin-bottom: 8px;
  	border: none;
  	-webkit-border-radius: 0;
       -moz-border-radius: 0;
          	border-radius: 0;
}

.accordion-heading {
  	border-bottom: 0 none;
}

.accordion-heading .accordion-toggle {
	position: relative;
  	display: block;
  	padding: 15px;
	background:#f8f8f8;
	text-decoration:none;
	font-size: 13px;
	-webkit-transition: all 0.1s linear 0s;
	-moz-transition: all 0.1s linear 0s;
	-ms-transition: all 0.1s linear 0s;
	-o-transition: all 0.1s linear 0s;
	transition: all 0.1s linear 0s;
}

.accordion-toggle {
  	cursor: pointer;
}
.accordion-toggle:hover{
	text-decoration:none;
}
.accordion-heading:hover .accordion-toggle,
.accordion-heading:hover .accordion-toggle.inactive {
	background:#666;
	color:#fff;
}
.accordion-heading .accordion-toggle.active {
	color:#fff;
	background:#666;
}
.accordion-heading .accordion-toggle.inactive {
	background:#f8f8f8;
}
.accordion-heading.accordionize .accordion-toggle i,
.accordion-heading.togglize .accordion-toggle i {
    position: absolute;
    top: 50%;
    right: 20px;
    margin: -8px 0 0 0;
    color: #666;
    font-size: 18px;
	-webkit-transition: all 0.1s linear 0s;
	-moz-transition: all 0.1s linear 0s;
	-ms-transition: all 0.1s linear 0s;
	-o-transition: all 0.1s linear 0s;
	transition: all 0.1s linear 0s;
}
.accordion-heading:hover .accordion-toggle i {
	color: #fff;
}
.accordion-heading .accordion-toggle.active i,
.accordion-heading:hover .accordion-toggle.inactive i {
	color: #666;	
}
.accordion-heading.accordionize .accordion-toggle.active i,
.accordion-heading.togglize .accordion-toggle.active i {
	color: #fff;
	-webkit-transform: rotate(180deg);
	-moz-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	-o-transform: rotate(180deg);
	transform: rotate(180deg);	
}

.accordion-heading.togglize .accordion-toggle i {
	margin: -8px 0 0 0;
	font-size: 18px;
}
.accordion-heading.togglize .accordion-toggle .fa-plus-circle{
	display:block;
}
.accordion-heading.togglize .accordion-toggle.active .fa-plus-circle{
	display:none;
}
.accordion-heading.togglize .accordion-toggle .fa-minus-circle{
	display:none;
}
.accordion-heading.togglize .accordion-toggle.active .fa-minus-circle{
	display:block;
}
.accordion-inner {
  	padding: 16px;
	border: 1px solid #efefef;
	background: #fff;
}

/* ==================================================
   Alerts
================================================== */

.alert {
  	margin-bottom: 20px;
  	padding: 15px 35px 15px 15px;
	border: 1px solid #efefef;
  	-webkit-border-radius: 0px;
       -moz-border-radius: 0px;
          	border-radius: 0px;
  	background-color: #f8f8f8;
	text-shadow: none;
}

.alert .close {
 	position: relative;
  	top: 0px;
  	right: -22px;
	color: #FFFFFF;	
	text-shadow: none;
  	line-height: 20px;
	
	-webkit-transition: opacity 0.1s linear 0s;
	-moz-transition: opacity 0.1s linear 0s;
	-ms-transition: opacity 0.1s linear 0s;
	-o-transition: opacity 0.1s linear 0s;
	transition: opacity 0.1s linear 0s;
}

.alert .close:hover {
	text-decoration:none;
	opacity: 1;
}
.alert-standard {
	border-color:#efefef;
  	background-color: #f8f8f8;
}
.alert-standard, .alert-standard .close {
	color:#666;
}
.alert-success {
	border-color:#d6e9c6;
  	background-color: #dff0d8;
}
.alert-success, .alert-success .close {
	color:#468847;
}
.alert-danger,
.alert-error {
	border-color:#ebccd1;
  	background-color: #f2dede;
}
.alert-danger, .alert-danger .close,
.alert-error, .alert-error .close {
	color:#b94a48;
}
.alert-info {
	border-color:#bce8f1;
  	background-color: #d9edf7;
}
.alert-info, .alert-info .close {
	color:#3a87ad;
}
.alert-warning {
	border-color:#faebcc;
  	background-color: #fcf8e3;
}
.alert-warning, .alert-warning .close {
	color:#c09853;
}

/* ==================================================
   Progress bars
================================================== */
@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
@-moz-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
@-o-keyframes progress-bar-stripes {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 40px 0;
  }
}
@keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  	margin-bottom: 15px;
  	height: 20px;
  	border-radius: 3px;
  	background-color: #eaeaea;
  	-webkit-box-shadow: none;
  	box-shadow: none;
	overflow: visible;
}
.progress-bar {
  	float: left;
  	width: 0%;
  	height: 100%;
  	background-color: #eaeaea;
  	-webkit-box-shadow: none;
  	box-shadow: none;
  	color: #ffffff;
  	text-align: center;
  	font-size: 12px;
  	line-height: 20px;
  	-webkit-transition: width 0.6s ease;
  	transition: width 0.6s ease;
	position: relative;
  	border-radius: 3px;
}
.progress-striped .progress-bar {
  	background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
  	background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  	background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  	background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 40px 40px;
}
.progress.active .progress-bar {
  	-webkit-animation: progress-bar-stripes 2s linear infinite;
  	animation: progress-bar-stripes 2s linear infinite;
}
.progress-bar-success {
  	background-color: #5cb85c;
}
.progress-striped .progress-bar-success {
  	background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
  	background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  	background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  	background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-bar-info {
  	background-color: #5bc0de;
}
.progress-striped .progress-bar-info {
  	background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
  	background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  	background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  	background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-bar-warning {
  	background-color: #f0ad4e;
}
.progress-striped .progress-bar-warning {
  	background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
  	background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  	background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  	background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-bar-danger {
  	background-color: #d9534f;
}
.progress-striped .progress-bar-danger {
  	background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
  	background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  	background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  	background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}

span.progress-bar-tooltip {
	position: absolute;
	position: absolute;
	top: -28px;
	right: 5px;
	display: block;
	padding: 4px 8px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	background-color: #333;
	color: #FFF;
	font-size: 11px;
	line-height: 15px;
	opacity: 0;
	filter: alpha(opacity=0);
	-o-border-radius: 3px;
}
span.progress-bar-tooltip:after {
	position: absolute;
	bottom: -5px;
	left: 13px;
	display: block;
	width: 0;
	border-width: 5px 5px 0;
	border-style: solid;
	border-color: #333 transparent;
	content: "";
}
.progress-label{
	text-transform:uppercase;
}
h1 small, h2 small, h3 small, h4 small, h5 small, h6 small, .h1 small, .h2 small, .h3 small, .h4 small, .h5 small, .h6 small, h1 .small, h2 .small, h3 .small, h4 .small, h5 .small, h6 .small, .h1 .small, .h2 .small, .h3 .small, .h4 .small, .h5 .small, .h6 .small{
	color:#666;
}
.form-control:focus {
  border-color: #ccc;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
          box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
}
.breadcrumb > li + li:before {
  content: "\203A";
  color:#fff;
  padding:0 8px;
}
.breadcrumb a {
  color: #fff;
}
.breadcrumb > .active {
  color: #fff;
  font-weight:700;
}
.label{
	font-size:10px!important;
	text-transform:uppercase;
	position:relative;
	top:-2px;
}
.pagination{
	margin-bottom:0;
	margin-top:50px;
}
.pagination > li > a, .pagination > li > span{
	color:#666;
}
.nav{
	margin:0;
}