@media (min-width: 1200px) {
  .container {
    width: 1040px;
  }
}

.btn-default:active, .btn-primary:active, .btn-success:active, .btn-info:active, .btn-warning:active, .btn-danger:active, .btn-default.active, .btn-primary.active, .btn-success.active, .btn-info.active, .btn-warning.active, .btn-danger.active {
  box-shadow: inset 0 3px 5px #00000020;
}

.btn:active, .btn.active {
  background-image: none;
}

.btn-default {
  text-shadow: 0 1px #fff;
  filter: none;
  filter: none;
  background-color: #fff;
  background-image: none;
  background-repeat: repeat-x;
  border-color: #e8e8e8;
}

.btn-default:hover, .btn-default:focus {
  background-color: #fafafa;
  background-position: 0 -15px;
  border-color: #e8e8e8;
}

.btn-default:active, .btn-default.active {
  background-color: #fafafa;
  border-color: #e8e8e8;
}

.btn-default:disabled, .btn-default[disabled] {
  background-color: #fafafa;
  background-image: none;
}

.btn-primary {
  border-color: #2b669a;
}

.btn-primary:hover, .btn-primary:focus {
  background-color: #2d6ca2;
}

.btn-primary:active, .btn-primary.active {
  background-color: #2d6ca2;
  border-color: #2b669a;
}

.btn-primary:disabled, .btn-primary[disabled] {
  background-color: #2d6ca2;
}

.btn-success {
  border-color: #3e8f3e;
}

.btn-success:hover, .btn-success:focus {
  background-color: #419641;
}

.btn-success:active, .btn-success.active {
  background-color: #419641;
  border-color: #3e8f3e;
}

.btn-success:disabled, .btn-success[disabled] {
  background-color: #419641;
}

.btn-info {
  border-color: #28a4c9;
}

.btn-info:hover, .btn-info:focus {
  background-color: #2aabd2;
}

.btn-info:active, .btn-info.active {
  background-color: #2aabd2;
  border-color: #28a4c9;
}

.btn-info:disabled, .btn-info[disabled] {
  background-color: #2aabd2;
}

.btn-warning {
  border-color: #e38d13;
}

.btn-warning:hover, .btn-warning:focus {
  background-color: #eb9316;
}

.btn-warning:active, .btn-warning.active {
  background-color: #eb9316;
  border-color: #e38d13;
}

.btn-warning:disabled, .btn-warning[disabled] {
  background-color: #eb9316;
}

.btn-danger {
  border-color: #b92c28;
}

.btn-danger:hover, .btn-danger:focus {
  background-color: #c12e2a;
}

.btn-danger:active, .btn-danger.active {
  background-color: #c12e2a;
  border-color: #b92c28;
}

.btn-danger:disabled, .btn-danger[disabled] {
  background-color: #c12e2a;
}

.btn-default, .btn-primary, .btn-success, .btn-info, .btn-warning, .btn-danger {
  -o-transition: all .3s ease .2s;
  -webkit-transition: all .3s .2s;
  -moz-transition: all .3s .2s;
  -ms-transition: all .3s .2s;
  text-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  text-transform: uppercase;
  letter-spacing: .07em;
  color: #ffffffe6;
  border-width: 0;
  border-radius: 20px;
  padding-left: 20px;
  padding-right: 20px;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.66857;
  text-decoration: none;
  transition: all .3s .1s;
  position: relative;
}

.btn-default {
  color: #333;
  border: 1px solid #ddd;
}

.btn-sm, .btn-group-sm > .btn {
  border-radius: 3px;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 11px;
}

.input-group-btn .btn-default, .input-group-btn .btn-primary, .input-group-btn .btn-success, .input-group-btn .btn-info, .input-group-btn .btn-warning, .input-group-btn .btn-danger {
  border-radius: 4px;
}

.thumbnail, .img-thumbnail {
  background-color: #fff;
  border: 1px solid #e8e8e8;
}

.dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
  background-color: #e8e8e8;
}

.dropdown-menu > .active > a, .dropdown-menu > .active > a:hover, .dropdown-menu > .active > a:focus {
  background-color: #357ebd;
}

.navbar-default {
  border-radius: 4px;
  box-shadow: inset 0 1px #ffffff26, 0 1px 5px #00000013;
}

.navbar-default .navbar-nav > .active > a {
  box-shadow: inset 0 3px 9px #00000013;
}

.navbar-brand, .navbar-nav > li > a {
  text-shadow: 0 1px #ffffff40;
}

.navbar-inverse .navbar-nav > .active > a {
  box-shadow: inset 0 3px 9px #00000040;
}

.navbar-inverse .navbar-brand, .navbar-inverse .navbar-nav > li > a {
  text-shadow: 0 -1px #00000040;
}

.navbar-static-top, .navbar-fixed-top, .navbar-fixed-bottom {
  border-radius: 0;
}

.alert {
  text-shadow: 0 1px #fff3;
  box-shadow: inset 0 1px #ffffff40, 0 1px 2px #0000000d;
}

.alert-success {
  border-color: #b2dba1;
}

.alert-info {
  border-color: #9acfea;
}

.alert-warning {
  border-color: #f5e79e;
}

.alert-danger {
  border-color: #dca7a7;
}

.list-group {
  border-radius: 4px;
  box-shadow: 0 1px 2px #00000013;
}

.list-group-item.active, .list-group-item.active:hover, .list-group-item.active:focus {
  border-color: #3278b3;
}

.panel {
  box-shadow: 0 1px 2px #0000000d;
}

.well {
  border-color: #dcdcdc;
  box-shadow: inset 0 1px 3px #0000000d, 0 1px #ffffff1a;
}

.nav-tabs-bar {
  width: 100%;
  height: 34px;
  background: #fafafa;
  border: 1px solid #e8e8e8;
  padding-right: 20px;
  line-height: 32px;
}

.nav-tabs {
  float: left;
  border: 0;
}

.nav-tabs > li {
  float: left;
  text-transform: uppercase;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 400;
  display: block;
  position: relative;
}

.nav-tabs > li > a {
  background: #fafafa;
  border-top: 0;
  border-bottom: 1px solid #0000;
  border-left: 0;
  border-right: 1px solid #e8e8e8;
  border-radius: 0;
  margin-right: 0;
  padding: 0 21px 0 20px;
  line-height: 32px;
  text-decoration: none;
  display: block;
}

.nav-tabs > li > a:hover {
  background: none;
  border-top: 0;
  border-bottom: 1px solid #0000;
  border-left: 0;
  border-right: 1px solid #e8e8e8;
  text-decoration: none;
}

.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
  border-top: 0;
  border-bottom: 1px solid;
  border-left: 0;
  border-right: 1px solid #e8e8e8;
}

.tab-content {
  padding: 50px 0 0;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.accordion {
  margin-bottom: 35px;
}

.accordion-group {
  border: none;
  border-radius: 0;
  margin-bottom: 8px;
}

.accordion-heading {
  border-bottom: 0;
}

.accordion-heading .accordion-toggle {
  -o-transition: all .1s linear 0s;
  background: #f8f8f8;
  padding: 15px;
  font-size: 13px;
  text-decoration: none;
  transition: all .1s linear;
  display: block;
  position: relative;
}

.accordion-toggle {
  cursor: pointer;
}

.accordion-toggle:hover {
  text-decoration: none;
}

.accordion-heading:hover .accordion-toggle, .accordion-heading:hover .accordion-toggle.inactive, .accordion-heading .accordion-toggle.active {
  color: #fff;
  background: #666;
}

.accordion-heading .accordion-toggle.inactive {
  background: #f8f8f8;
}

.accordion-heading.accordionize .accordion-toggle i, .accordion-heading.togglize .accordion-toggle i {
  color: #666;
  -o-transition: all .1s linear 0s;
  margin: -8px 0 0;
  font-size: 18px;
  transition: all .1s linear;
  position: absolute;
  top: 50%;
  right: 20px;
}

.accordion-heading:hover .accordion-toggle i {
  color: #fff;
}

.accordion-heading .accordion-toggle.active i, .accordion-heading:hover .accordion-toggle.inactive i {
  color: #666;
}

.accordion-heading.accordionize .accordion-toggle.active i, .accordion-heading.togglize .accordion-toggle.active i {
  color: #fff;
  transform: rotate(180deg);
}

.accordion-heading.togglize .accordion-toggle i {
  margin: -8px 0 0;
  font-size: 18px;
}

.accordion-heading.togglize .accordion-toggle .fa-plus-circle {
  display: block;
}

.accordion-heading.togglize .accordion-toggle.active .fa-plus-circle, .accordion-heading.togglize .accordion-toggle .fa-minus-circle {
  display: none;
}

.accordion-heading.togglize .accordion-toggle.active .fa-minus-circle {
  display: block;
}

.accordion-inner {
  background: #fff;
  border: 1px solid #efefef;
  padding: 16px;
}

.alert {
  text-shadow: none;
  background-color: #f8f8f8;
  border: 1px solid #efefef;
  border-radius: 0;
  margin-bottom: 20px;
  padding: 15px 35px 15px 15px;
}

.alert .close {
  color: #fff;
  text-shadow: none;
  -o-transition: opacity .1s linear 0s;
  line-height: 20px;
  transition: opacity .1s linear;
  position: relative;
  top: 0;
  right: -22px;
}

.alert .close:hover {
  opacity: 1;
  text-decoration: none;
}

.alert-standard {
  background-color: #f8f8f8;
  border-color: #efefef;
}

.alert-standard, .alert-standard .close {
  color: #666;
}

.alert-success {
  background-color: #dff0d8;
  border-color: #d6e9c6;
}

.alert-success, .alert-success .close {
  color: #468847;
}

.alert-danger, .alert-error {
  background-color: #f2dede;
  border-color: #ebccd1;
}

.alert-danger, .alert-danger .close, .alert-error, .alert-error .close {
  color: #b94a48;
}

.alert-info {
  background-color: #d9edf7;
  border-color: #bce8f1;
}

.alert-info, .alert-info .close {
  color: #3a87ad;
}

.alert-warning {
  background-color: #fcf8e3;
  border-color: #faebcc;
}

.alert-warning, .alert-warning .close {
  color: #c09853;
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }

  to {
    background-position: 0 0;
  }
}

@-moz-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }

  to {
    background-position: 0 0;
  }
}

@-o-keyframes progress-bar-stripes {
  from {
    background-position: 0 0;
  }

  to {
    background-position: 40px 0;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }

  to {
    background-position: 0 0;
  }
}

.progress {
  height: 20px;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: #eaeaea;
  border-radius: 3px;
  margin-bottom: 15px;
  overflow: visible;
}

.progress-bar {
  float: left;
  width: 0%;
  height: 100%;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  text-align: center;
  background-color: #eaeaea;
  border-radius: 3px;
  font-size: 12px;
  line-height: 20px;
  transition: width .6s;
  position: relative;
}

.progress-striped .progress-bar {
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25% 50%, #ffffff26 50% 75%, #0000 75%, #0000);
  background-size: 40px 40px;
}

.progress.active .progress-bar {
  animation: 2s linear infinite progress-bar-stripes;
}

.progress-bar-success {
  background-color: #5cb85c;
}

.progress-striped .progress-bar-success {
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25% 50%, #ffffff26 50% 75%, #0000 75%, #0000);
}

.progress-bar-info {
  background-color: #5bc0de;
}

.progress-striped .progress-bar-info {
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25% 50%, #ffffff26 50% 75%, #0000 75%, #0000);
}

.progress-bar-warning {
  background-color: #f0ad4e;
}

.progress-striped .progress-bar-warning {
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25% 50%, #ffffff26 50% 75%, #0000 75%, #0000);
}

.progress-bar-danger {
  background-color: #d9534f;
}

.progress-striped .progress-bar-danger {
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25% 50%, #ffffff26 50% 75%, #0000 75%, #0000);
}

span.progress-bar-tooltip {
  color: #fff;
  opacity: 0;
  filter: alpha(opacity= 0);
  -o-border-radius: 3px;
  background-color: #333;
  border-radius: 3px;
  padding: 4px 8px;
  font-size: 11px;
  line-height: 15px;
  display: block;
  position: absolute;
  top: -28px;
  right: 5px;
}

span.progress-bar-tooltip:after {
  width: 0;
  content: "";
  border: 5px solid #0000;
  border-top-color: #333;
  border-bottom: 0 solid #333;
  display: block;
  position: absolute;
  bottom: -5px;
  left: 13px;
}

.progress-label {
  text-transform: uppercase;
}

h1 small, h2 small, h3 small, h4 small, h5 small, h6 small, .h1 small, .h2 small, .h3 small, .h4 small, .h5 small, .h6 small, h1 .small, h2 .small, h3 .small, h4 .small, h5 .small, h6 .small, .h1 .small, .h2 .small, .h3 .small, .h4 .small, .h5 .small, .h6 .small {
  color: #666;
}

.form-control:focus {
  border-color: #ccc;
  box-shadow: inset 0 1px 1px #00000013;
}

.breadcrumb > li + li:before {
  content: "›";
  color: #fff;
  padding: 0 8px;
}

.breadcrumb a {
  color: #fff;
}

.breadcrumb > .active {
  color: #fff;
  font-weight: 700;
}

.label {
  text-transform: uppercase;
  position: relative;
  top: -2px;
  font-size: 10px !important;
}

.pagination {
  margin-top: 50px;
  margin-bottom: 0;
}

.pagination > li > a, .pagination > li > span {
  color: #666;
}

.nav {
  margin: 0;
}

/*# sourceMappingURL=index.75bbb279.css.map */
